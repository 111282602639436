<template>
    <div class="shop-index">
        <a-row class="si-container bg">
            <a-col class="si-heaer" :sm="2" :md="4" :xxl="6"></a-col>
            <a-col :sm="20" :md="16" :xxl="12">
                <div class="si-heaer">
                    <img class="logo" v-if="config" :src="config.logo" alt="">
                    <div>
                        <span>{{ $store.state.username}}</span>
                        <a-button type="link" @click="logoutNow">退出</a-button>
                    </div>
                </div>
            </a-col>
            <a-col class="si-heaer" :sm="2" :md="4" :xxl="6"></a-col>
        </a-row>
        <a-row class="si-container bg">
            <a-col :sm="2" :md="4" :xxl="6"></a-col>
            <a-col :sm="20" :md="16" :xxl="12">
                <a-spin :spinning="$store.state.loading">
                    <router-view></router-view>
                </a-spin>
            </a-col>
            <a-col :sm="2" :md="4" :xxl="6"></a-col>
        </a-row>
        
    </div>
</template>
<script>
import {logout} from '@/api/http.js'
import setModel from '@/api/saas/set.js'
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const _d = reactive({
            config:null
        })
        setModel.getWebSite(res=>{
            _d.config = res
        })

        function logoutNow(){
            logout()
        }
        return {
            ...toRefs(_d),
            logoutNow
        }
    },
}
</script>
<style lang="scss" scoped>
body{
    padding: 0;
    margin: 0;
}
.bg{
    background: #f0f2f5;
}
.shop-index{
   .si-container{
        margin-bottom: 24px;
    }
}
.si-heaer{
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
        height: 40px;
    }
}
</style>